import React from "react"
import { graphql } from "gatsby"

// Components
import Layout from "../components/Layout"
import Wrapper from "../components/Wrapper/Wrapper"
import SEO from "../components/SEO/SEO"
import Title from "../components/Title"
import Button from "../components/Button/Button"

const PostTemplate = ({ data, pageContext }) => {
  return (
    <Layout darkNav className={`bg-monochromeSky-superLight`}>
      <SEO page={data?.wpPost} />
      <Wrapper className={`py-14 pb-20`}>
        <article
          className="prose-a: prose mx-auto mt-12  prose-headings:font-bold prose-a:font-semibold prose-a:text-primaryBlue prose-a:no-underline prose-strong:font-bold lg:prose-xl"
          dangerouslySetInnerHTML={{ __html: data.wpPost.content }}
        />
        <Button primary className={`mx-auto mt-10`}>
          Sign up
        </Button>
      </Wrapper>
    </Layout>
  )
}

export default PostTemplate

export const query = graphql`
  query postQuery($pageTitle: String) {
    wpPost(title: { eq: $pageTitle }) {
      seo {
        ...SEO
      }
      content
    }
  }
`
